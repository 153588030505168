import React, { ReactNode } from 'react'

export interface IThemeContext {
  theme: string
  setTheme?: React.Dispatch<React.SetStateAction<string>>
}

const defaultContext = {
  theme: 'light',
}

export const ThemeContext = React.createContext<IThemeContext>(defaultContext)

const ThemeContextProvider = ({ children }: { children: ReactNode }) => {
  const [theme, setTheme] = React.useState('light')
  React.useEffect(() => {
    function loadTheme() {
      const prefersColorSchemeDark = window.matchMedia(
        '(prefers-color-scheme: dark)'
      )
      if (prefersColorSchemeDark.matches) {
        return 'dark'
      }
      return 'light'
    }
    setTheme(loadTheme())
  }, [])
  return (
    <ThemeContext.Provider value={{ theme: theme, setTheme: setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContextProvider
